import React from "react"
import { graphql } from "gatsby"

import ContactLayout from "../modules/contactLayout"

export const query = graphql`
  {
    sanitySiteConfig {
      ...SiteInformation
    }
    sanityContactInfo {
      title
      heading
      _rawTagline
      _rawContent(resolveReferences: { maxDepth: 10 })
      formTitle
      formLink
      _rawFormSubtitle
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`

export default props => {
  const page = props.data.sanityContactInfo
  const seo = {
    title: page.title,
    customTitle: page.seo?.metaTitle,
    description: page.seo?.description,
    image: page.seo?.openGraphImage?.asset?.url,
    noIndex: page.seo?.noIndex,
  }

  return <ContactLayout seo={seo} page={page} />
}
